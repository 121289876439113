import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import MelidataTrackerService from '@mshops-web-core/melidata-tracker';

import Button from '../../../../components/Button';
import PriceTable from '../../../../components/PriceTable';
import ContentChart from '../../../../wordings/chart/index';
import deviceSizes from '../../../../utils/deviceSizes';
import { BenefitsBaner } from '../../../../constants/melidata-events/activate-events';

const Chart = ({ i18n, deviceType, siteId }) => {
  const [showAllRows, setShowAllRows] = useState(false);
  const data = ContentChart(i18n, siteId);
  const { title, copy, shopButton, functionalityButton, chartItems } = data;

  const buttonSize = deviceSizes[deviceType];

  const handleToggleRows = useCallback(() => {
    setShowAllRows(!showAllRows);
  }, [showAllRows]);

  const handleClickButton = useCallback(() => {
    MelidataTrackerService.notify(BenefitsBaner);
  }, []);

  return (
    <section className="ui-ms-landing-chart">
      <div className="ui-ms-landing-chart__container">
        <h2 className="ui-ms-landing-chart__title">{title}</h2>
        <p className="ui-ms-landing-chart__copy">{copy}</p>
        <Button
          type="submit"
          text={shopButton}
          colorPalette="black"
          size={buttonSize}
          onClick={handleClickButton}
        />
        <PriceTable items={chartItems} showAllRows={showAllRows} />
        <Button
          text={functionalityButton}
          variant="secondary"
          colorPalette="black"
          onClick={handleToggleRows}
          hasIconRight={false}
          size={buttonSize}
          type="button"
        />
      </div>
    </section>
  );
};

Chart.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.string,
  siteId: PropTypes.string.isRequired,
};

export default Chart;
