import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import MelidataTrackerService from '@mshops-web-core/melidata-tracker';

import dataShopWithoutLimits from '../../../../wordings/shopWithoutLimits';
import CardWithCta from '../../../../components/CardWithCta';
import { NoLimitsBanner } from '../../../../constants/melidata-events/activate-events';

const ShopWithoutLimits = ({ i18n, deviceType }) => {
  const data = dataShopWithoutLimits(i18n);
  const { title, description, image, button } = data;
  const namespace = 'ui-ms-landing-shop-without-limits';

  const handleClickCta = useCallback(() => {
    MelidataTrackerService.notify(NoLimitsBanner);
  }, []);

  return (
    <section className={namespace}>
      <CardWithCta
        image={image}
        title={title}
        text={description}
        cta={button}
        deviceType={deviceType}
        onClick={handleClickCta}
      />
    </section>
  );
};

ShopWithoutLimits.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

export default ShopWithoutLimits;
